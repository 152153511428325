<template>
  <v-col class="px-5 pt-10 pb-5 height-100 d-flex flex-column">
    <resize-observer @notify="tableHeight"/>
    <!--    <v-col class="height-100 pa-5 d-flex flex-column scroll">-->
    <v-item-group v-model="tab" mandatory class="mb-10 ml-2 orders-tabs hidden">
      <v-row class="ma-0">
        <v-col
          cols="12"
          md="4"
          class="pa-0"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              elevation="0"
              class="d-flex flex-column justify-center pa-4 fz-14 font-weight-bold"
              height="98"
              :ripple="false"
              @click="toggle"
            >
              <v-icon size="32">$financePaidOrders</v-icon>
              Paid orders {{ totalCounts ? '(' + totalCounts.paid + ')' : null }}
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="pa-0"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              elevation="0"
              class="d-flex flex-column justify-center pa-4 fz-14 font-weight-bold border-l-lightgray border-r-lightgray"
              height="98"
              :ripple="false"
              @click="toggle"
            >
              <v-icon size="32">$financeUnpaidOrders</v-icon>
              Unpaid orders {{ totalCounts ? '(' + totalCounts.unpaid + ')' : null }}
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="pa-0"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              elevation="0"
              class="d-flex flex-column justify-center pa-4 fz-14 font-weight-bold"
              height="98"
              :ripple="false"
              @click="toggle"
            >
              <v-icon size="32">$financeTotalOrders</v-icon>
              Total orders {{ totalCounts ? '(' + totalCounts.total + ')' : null }}
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <v-row
      no-gutters
      class="my-2 flex-grow-0"
      :class="{'mx-2': $vuetify.breakpoint.smAndUp, 'mx-0': $vuetify.breakpoint.xsOnly, 'flex-column': $vuetify.breakpoint.xsOnly, 'align-start': $vuetify.breakpoint.xsOnly}"
    >
      <!--Date-->
      <v-col cols="12" class="mb-4 d-flex">
        <v-btn-toggle
          class="rounded-lg height-30 box-shadow interval black--text hidden"
          style="padding-left: 2px; padding-right: 2px"
          :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
          background-color="#F2F4F5"
          v-model="interval"
          borderless
          dense>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getWeek"
            height="26">
            Week
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getMonth"
            height="26">
            Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getThreeMonth"
            height="26">
            3 months
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getYear"
            height="26">
            {{ getYearNow }} year
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getAllTime"
            height="26">
            All time
          </v-btn>
        </v-btn-toggle>
        <v-btn
          color="#010820"
          class="rounded-lg fz-13 fw-600 text-none mr-5"
          :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
          height="30"
          @click="showDatepicker"
          outlined>
          {{ queryOptions.dateStart && queryOptions.dateEnd ? 'Period: ' + queryOptions.dateStart + ' - ' + queryOptions.dateEnd : 'Specify period' }}
          <div @click.stop>
            <v-icon size="16" v-if="queryOptions.dateStart && queryOptions.dateEnd" class="ml-2" @click="getAllTime">mdi-close</v-icon>
          </div>
        </v-btn>
      </v-col>
      <!--Dashboard-->
      <DashboardOrder
        :dateStart="queryOptions.dateStart"
        :dateEnd="queryOptions.dateEnd"
        :searchClient="queryOptions.searchClient"
        :searchCompany="queryOptions.searchCompany"
        @ordersUpdate="changeStatusAndDate"
      />
    </v-row>
    <v-row class="ma-0 flex-grow-1" ref="table">
      <v-col class="pa-0">
        <v-data-table
          :headers="headers"
          :items="orders"
          :search="search"
          :header-props="{ sortIcon: 'mdi-menu-down' }"
          :server-items-length="queryOptions.totalItems"
          :options.sync="options"
          hide-default-footer
          @click:row="$refs.editOrder.getOrder($event.id)"
          class="mt-5 orders-table table-vertical-line"
        >
          <template v-slot:header.number="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Number"
              v-model="debouncedInputValue.searchNumber"
              @input="debouncedInput('searchNumber')"
              outlined
              clearable
              class="table-search d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.name="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Project"
              v-model="debouncedInputValue.searchName"
              @input="debouncedInput('searchName')"
              outlined
              clearable
              class="table-search d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.client="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Client"
              v-model="debouncedInputValue.searchClient"
              @input="debouncedInput('searchClient')"
              outlined
              clearable
              class="table-search width-140 d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.company="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Company"
              v-model="debouncedInputValue.searchCompany"
              @input="debouncedInput('searchCompany')"
              outlined
              clearable
              class="table-search width-140 d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.assignee="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Assignee"
              v-model="debouncedInputValue.searchExecutor"
              @input="debouncedInput('searchExecutor')"
              outlined
              clearable
              class="table-search width-140 d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.manager="">
            <v-text-field
              @click.stop
              prepend-inner-icon="$search"
              placeholder="Manager"
              v-model="debouncedInputValue.searchManager"
              @input="debouncedInput('searchManager')"
              outlined
              clearable
              class="table-search width-140 d-inline-block ma-0 input-clear-small"
              dense
            ></v-text-field>
          </template>
          <template v-slot:header.status="">
            <v-select
              :items="statusCounts ? statusLabels.filter(item => statusCounts[item.value] > 0) : statusLabels"
              v-model="queryOptions.orderStatuses"
              item-text="label"
              item-value="value"
              single-line
              outlined
              multiple
              class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash status-select"
              dense
              clearable
              return-object
              item-color="#010820"
              hide-details
              hide-spin-buttons
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template v-slot:selection="data">
                <v-col class="pa-0">
                  <span style="display: inline-block; width: 8px; height: 8px; margin-right: 2px;" class="rounded-circle" :style="color(data.item.value)"></span>
                </v-col>
              </template>
              <template v-slot:item="data">
                <span style="width: 30px; padding-right: 3px; color: grey;"> {{ statusCounts[data.item.value] }} </span>
                <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle" :style="color(data.item.value)"></span>
                <span style="max-width: 80%" class="fz-14 font-weight-medium text-black">{{ data.item.label }}</span>
              </template>
              <template v-slot:prepend-inner>
                <span style="padding-top: 5px;">Status</span>
                <v-icon style="padding-top: 2px;" size="20" v-text="'mdi-chevron-down'"></v-icon>
              </template>
            </v-select>
          </template>
          <template v-slot:item.name="{ item }">
            <div style="max-width: 10vw" class="one-line">{{ item.name }}</div>
          </template>
          <template v-slot:item.status="{ item }">
            <v-row class="ma-0">
              <v-col class="pa-0">
                <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle" :style="color(item.status.value)"></span>
                <span style="max-width: 80px">{{ item.status.label }}</span>
                <v-tooltip content-class="tooltip-right" right max-width="600" v-if="item.status.id === 'CANCELED'">
                  <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="one-line py-0 ml-1"
                      >
                    <v-icon>$questionMark</v-icon>
                  </span>
                  </template>
                  <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                    <div
                      v-for="(reason, index) of item.itemsCancellationReasons"
                      :key="index"
                      class="width-100"
                      style="word-break: break-all"
                    >
                        <span>
                          {{ reason }}
                        </span>
                    </div>
                  </v-col>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span class="one-line">{{ formatDate(item.createdAt) }}</span>
          </template>
          <template v-slot:item.dateStart="{ item }">
            <span class="one-line">{{ item.dateStart ? formatDate(item.dateStart) : '' }}</span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span class="one-line">{{ formatDate(item.updatedAt) }}</span>
          </template>
          <template v-slot:item.dateEnd="{ item }">
            <v-col class="pa-0">
              <v-row class="ma-0">
                  <span class="one-line"
                        :class="{'color-primary': item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction, 'fw-600': item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction}">
                    {{ item.dateEnd ? formatDate(item.dateEnd) : '' }}
                  </span>
              </v-row>
              <v-row v-if="item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction" class="ma-0">
                  <span class="one-line fz-10 mt-n1">
                    {{ formatDate(item.dateEndPrediction) }}
                  </span>
              </v-row>
            </v-col>
          </template>
          <template v-slot:item.client="{ item }">
            <div class="one-line">{{ item.client.name }}</div>
          </template>
          <template v-slot:item.company="{ item }">
            <div class="one-line">{{ item.client.company ? item.client.company.name : '' }}</div>
          </template>
          <template v-slot:item.assignee="{ item }">
            <div v-if="item.executors.length === 0" class="text-black">
              Not assigned
            </div>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="my-auto">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="one-line py-0"
                  >
                    <div>
                      <span class="text-black">{{ item.executors[0].businessName }}</span>
                      <span
                        v-if="item.executors.length > 1"
                        class="grey--text"
                      >
                           and {{ item.executors.length - 1 }}
                        </span>
                    </div>
                  </div>
                </div>
              </template>
              <v-col class="d-flex flex-column flex-wrap">
                <div
                  v-for="(executor, index) in item.executors"
                  :key="executor.businessName"
                >
                  {{ executor.businessName }}
                  <v-divider v-if="item.executors.length - 1 !== index && item.executors.length !== 1" class="white opacity-25 my-2"></v-divider>
                </div>
              </v-col>
            </v-tooltip>
          </template>
          <template v-slot:item.totalCost="{ item }">
            <v-row class="ma-0 flex-nowrap">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="one-line my-auto"
                      :class="{'text-pink': !item.paymentDone, 'fw-600': !item.paymentDone }"
                    >
                      {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                      {{ Math.round(+item.totalCost) | numberFormat }}
                      {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                    </span>
                </template>
                <v-col>
                  <v-row class="ma-0">
                    <span class="mr-10 fz-13 fw-600 white--text">Final cost</span>
                    <v-spacer></v-spacer>
                    <span class="fz-13 fw-600 white--text">
                        {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                        {{ Math.round(+item.totalCost) | numberFormat }}
                        {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                      </span>
                  </v-row>
                  <v-divider class="white opacity-25"></v-divider>
                  <template v-for="payment in item.operations.filter(el => el.moneyIn)">
                    <v-row class="ma-0" :key="payment.id">
                      <span class="mr-10 fz-13 fw-600 white--text">{{ formatDate(payment.date) }}</span>
                      <v-spacer></v-spacer>
                      <span class="fz-13 fw-600 white--text">
                          {{ payment.moneyIn.symbol !== '₽' ? payment.moneyIn.symbol : '' }}
                          {{ Math.round(+payment.moneyIn.amount) | numberFormat }}
                          {{ payment.moneyIn.symbol === '₽' ? payment.moneyIn.symbol : '' }}
                        </span>
                    </v-row>
                    <v-divider class="white opacity-25" :key="`${payment.id}divider`"></v-divider>
                  </template>
                  <v-row class="ma-0">
                    <span class="mr-10 fz-13 fw-600 white--text">Reminder</span>
                    <v-spacer></v-spacer>
                    <span class="fz-13 fw-600 white--text">
                        {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                        {{ Math.round(+item.paymentRemainder) | numberFormat }}
                        {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                      </span>
                  </v-row>
                </v-col>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-icon @click.stop="showPrice(item)" size="20" class="stroke-gray">$priceEdit</v-icon>
            </v-row>
          </template>
          <template v-slot:item.salaryPayments="{ item }">
            <v-row class="ma-0 flex-nowrap align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="my-auto">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="one-line"
                          :class="{
                           'text-orange': +item.salaryPayments.remainderOffice.amount > 0,
                           'text-pink': +item.salaryPayments.remainderRemote.amount > 0,
                           'fw-600': +item.salaryPayments.remainder.amount > 0
                          }"
                        >
                          {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                          {{ Math.round(+item.salaryPayments.total.amount) | numberFormat }}
                          {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                        </span>
                    <br/>
                    <span
                      class="one-line gray"
                      v-if="item.salaryPayments.total.amount !== item.salaryPayments.expectedTotal.amount"
                    >
                          {{ item.salaryPayments.expectedTotal.symbol !== '₽' ? item.salaryPayments.expectedTotal.symbol : '' }}
                          {{ Math.round(item.salaryPayments.expectedTotal.amount) }}
                          {{ item.salaryPayments.expectedTotal.symbol === '₽' ? item.salaryPayments.expectedTotal.symbol : '' }}
                        </span>
                  </div>
                </template>
                <v-col>
                  <v-row class="ma-0">
                    <span class="mr-10 fz-13 fw-600 white--text">Final cost</span>
                    <v-spacer></v-spacer>
                    <span class="fz-13 fw-600 white--text">
                        {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                        {{ Math.round(+item.salaryPayments.total.amount) | numberFormat }}
                        {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                      </span>
                  </v-row>
                  <v-divider class="white opacity-25"/>
                  <v-row
                    v-for="payment in item.salaryPayments.byDesigner"
                    :key="payment.designer.id"
                    class="ma-0"
                  >
                      <span class="mr-10 fz-13 fw-600 white--text">
                        {{ payment.designer.businessName }}
                      </span>
                    <v-spacer/>
                    <span class="fz-13 fw-600 white--text">
                        {{ item.currencySymbol !== '₽' ? item.currencySymbol : '' }}
                        {{ Math.round(+payment.paid.amount) | numberFormat }}
                        {{ item.currencySymbol === '₽' ? item.currencySymbol : '' }}
                      </span>
                  </v-row>
                  <v-divider class="white opacity-25"/>
                  <v-row class="ma-0">
                    <span class="mr-10 fz-13 fw-600 white--text">Reminder</span>
                    <v-spacer></v-spacer>
                    <span class="fz-13 fw-600 white--text">
                        {{ item.salaryPayments.remainder.symbol !== '₽' ? item.salaryPayments.remainder.symbol : '' }}
                        {{ Math.round(+item.salaryPayments.remainder.amount) | numberFormat }}
                        {{ item.salaryPayments.remainder.symbol === '₽' ? item.salaryPayments.remainder.symbol : '' }}
                      </span>
                  </v-row>
                </v-col>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-icon @click.stop="showSalary(item)" size="20" class="stroke-gray">$priceEdit</v-icon>
            </v-row>
          </template>
          <template v-slot:item.manager="{ item }">
            <div class="one-line">
              <v-avatar
                :color="item.manager.avatarUrl ? 'transparent' : 'primary'"
                size="22"
                class="mr-3 my-auto"
              >
                <img
                  v-if="item.manager.avatarUrl"
                  :src="item.manager.avatarUrl"
                >
                <span v-else class="white--text">{{ item.manager.businessInitials || '??' }}</span>
              </v-avatar>
              {{ item.manager.businessName }}
            </div>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="370"
              content-class="rounded-xl"
              overlay-color="rgba(9, 24, 73, 0.2)"
              overlay-opacity="1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  x-small
                  icon
                  fab
                >
                  <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card class="pa-7">
                  <v-card-text class="py-0">
                    <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete order #{{ item.id }}: {{ item.name }}?</div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-space-between pa-0">
                    <v-btn
                      class="rounded-lg text-none fz-16 fw-600"
                      outlined
                      width="150"
                      height="40"
                      @click="dialog.value = false"
                    >Return</v-btn>
                    <v-btn
                      elevation="0"
                      color="#FF0000"
                      width="150"
                      height="40"
                      class="rounded-lg white--text text-none fz-16 fw-600"
                      @click="deleteOrder(item)"
                    >
                      <v-icon left class="stroke-white">
                        $delete
                      </v-icon>
                      Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
        <v-row class="ma-0 bb-lgray">
          <v-btn
            @click="$refs.editOrder.addNewOrder()"
            height="50"
            text
            tile
            color="#7552CC"
            class="text-none px-3 fz-14"
          >
            <b class="fz-16 fw-600 mr-1"> + </b>Add new order
          </v-btn>
        </v-row>
        <EditOrder
          @getOrders="getOrders"
          ref="editOrder"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0 flex-grow-0">
      <v-btn
        height="40"
        class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="queryOptions.page === 1"
        @click="queryOptions.page--"
        color="primary">
        <v-icon>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
      <v-pagination
        v-model="queryOptions.page"
        class="my-4 pagination text-black fw-600"
        :length="tableOptions.totalPages"
        :total-visible="7"
        previous-aria-label="Previous"
      >
      </v-pagination>
      <v-btn
        height="40"
        class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="tableOptions.totalPages === queryOptions.page"
        @click="queryOptions.page++"
        color="primary">
        Next
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-overlay
      :z-index="0"
      :value="overlay"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
    <!--    </v-col>-->
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="range"
      is-range
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      v-model="date">
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
    <!--    payment history -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      style="height: 100vh;"
      v-model="priceHistoryShow"
    >
      <PaymentsHistory
        :priceInfo="priceInfo"
        @closePaymentAdditional="closePaymentAdditional"
        @removePayment="removePayment"
      />
    </v-navigation-drawer>
    <!--    invoices -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      style="height: 100vh;"
      v-model="priceInvoicesShow"
    >
      <payments-invoices-reports
        type="invoice"
        :priceInfo="priceInfo"
        @getOrders="getOrders"
        @closePaymentAdditional="closePaymentAdditional" />
    </v-navigation-drawer>
    <!--    reports -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      style="height: 100vh;"
      v-model="priceReportsShow"
    >
      <v-col class="d-flex flex-column height-100 pa-0" v-if="priceInfo">
        <payments-invoices-reports
          type="reports"
          :priceInfo="priceInfo"
          @getOrders="getOrders"
          @closePaymentAdditional="closePaymentAdditional" />
      </v-col>
    </v-navigation-drawer>
    <!--    main info payments -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      style="height: 100vh;"
      v-model="priceInfoShow"
    >
      <price-info
        ref="priceInfo"
        :priceInfo="priceInfo"
        :invoiceSelected="invoiceSelected"
        :createPayment="createPayment"
        :paymentAccounts="paymentAccounts"
        :priceHistoryShow="priceHistoryShow"
        :priceInvoicesShow="priceInvoicesShow"
        :priceReportsShow="priceReportsShow"
        :paymentRemainder="paymentRemainder"
        @closePriceInfo="closePriceInfo"
        @changePaymentFromInvoice="changePaymentFromInvoice"
        @openPriceHistory="openPriceHistory"
        @openPriceInvoices="openPriceInvoices"
        @openPriceReports="openPriceReports"
        @addContract="addContract"
        @updatePayment="updatePayment"
        @deleteContract="deleteContract"
      />
    </v-navigation-drawer>
    <!--    add salary operation -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      :class="{'mr-76': $vuetify.breakpoint.mdAndUp, 'z-index-200': $vuetify.breakpoint.smAndDown}"
      style="height: 100vh;"
      v-model="salaryDesignerShow"
    >
      <salary-operation
        ref="salaryOperation"
        :salaryInfo="salaryInfo"
        :priceInfo="priceInfo"
        :createDesignerPayment="createDesignerPayment"
        :paymentAccounts="paymentAccounts"
        :currency="currency"
        @closeSalaryDesignerShow="closeSalaryDesignerShow"
        @datepickerSalaryClick="datepickerSalaryClick"
        @closePriceInfo="closePriceInfo"
        @updateDesignerPayment="updateDesignerPayment"
        @removePayment="removePayment"
      />
    </v-navigation-drawer>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="date"
      :max-date='new Date()'
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      v-model="createDesignerPaymentDate">
      <template v-slot="">
        <button
          ref="datepickerSalaryShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
    <!--    salary info -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      style="height: 100vh;"
      v-model="salaryInfoShow"
    >
      <salary-info
        :priceInfo="priceInfo"
        @closePriceInfo="closePriceInfo"
        @showDesignerSalary="showDesignerSalary"
        @removePayment="removePayment"
      />
    </v-navigation-drawer>
    <!--    add contract -->
    <v-dialog
      transition="dialog-bottom-transition"
      content-class="rounded-4"
      max-width="560"
      v-model="attachContractShow"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
    >
      <template v-slot:default="">
        <v-card class="rounded-4">
          <v-toolbar
            height="70"
            elevation="0"
            class="bb-gray"
          >
            <p class="fz-16 font-weight-bold text-black ff ma-0 ml-3 pa-0">Add contract link</p>
            <v-spacer></v-spacer>
            <v-icon class="close-icon" @click="attachContractShow = false">$close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-col class="px-1">
              <v-form ref="contractUrl">
                <p class="fz-14 text-gray mb-1">
                  Add contract link
                </p>
                <v-text-field
                  outlined
                  single-line
                  v-model="contractUrl"
                  class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium input-border"
                  dense
                  @change="getContractNumber"
                  :rules="[rules.required]"
                ></v-text-field>
                <p class="fz-14 text-gray mb-1">
                  Contract number
                </p>
                <v-text-field
                  outlined
                  single-line
                  v-model="contractNumber"
                  class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium input-border"
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
                <p class="fz-14 text-gray mb-1">
                  Date
                </p>
                <vc-date-picker
                  locale="en"
                  class="h-full flex-grow-1"
                  :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                  :popover="{ visibility: 'click' }"
                  :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                  mode="date"
                  :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                  v-model="contractDate">
                  <template v-slot="{inputValue}">
                    <v-text-field
                      outlined
                      single-line
                      :value="inputValue"
                      class="rounded-lg fz-14 mb-3 font-weight-medium input-border text-black"
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                </vc-date-picker>
                <v-row class="ma-0 pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    color="primary"
                    class="rounded-lg fz-16 fw-600 text-none ml-auto"
                    width="155"
                    @click="attachContract"
                  >Send</v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-overlay
      :z-index="0"
      :value="priceInfoShow || salaryInfoShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="closePriceInfo"
    />
    <v-snackbars
      :messages.sync="messages"
      color="red"
      timeout="2000"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-col>
</template>

<script>
import EditOrder from '@/components/App/Orders/EditOrder'
import DashboardOrder from '@/components/App/Dashboard/DashboardOrder'
import VSnackbars from 'v-snackbars'
import numberFormat from '@/helpers/numberFormat'
import { statusColor } from '@/helpers/staticColors'
import gql from 'graphql-tag'
import PaymentsHistory from '@/components/App/Orders/PaymentsHistory'
import PaymentsInvoicesReports from '@/components/App/Orders/PaymentsInvoicesReports'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import SalaryInfo from '@/components/App/Orders/SalaryInfo'
import SalaryOperation from '@/components/App/Orders/SalaryOperation'
import { Locale } from 'v-calendar'
import PriceInfo from '@/components/App/Orders/PriceInfo'
import { mapMutations } from 'vuex'
import _ from 'lodash'
const locale = new Locale()

export default {
  name: 'OrderTab',
  props: ['id', 'new', 'currency'],
  components: { PriceInfo, SalaryOperation, SalaryInfo, PaymentsInvoicesReports, PaymentsHistory, 'v-snackbars': VSnackbars, EditOrder, ResizeObserver, DashboardOrder },
  filters: {
    numberFormat
  },
  data () {
    return {
      debouncedInputValue: {
        searchClient: '',
        searchCompany: '',
        searchManager: '',
        searchName: '',
        searchNumber: '',
        searchExecutor: ''
      },
      tab: 2,
      dialog: false,
      loading: false,
      totalCounts: null,
      statusCounts: null,
      priceInfoShow: false,
      priceInvoicesShow: false,
      priceReportsShow: false,
      priceHistoryShow: false,
      statusSelectorShow: false,
      contractUrl: null,
      contractNumber: null,
      contractTitle: null,
      contractDate: new Date(),
      attachContractShow: false,
      salaryInfoShow: false,
      salaryDesignerShow: false,
      priceInfo: null,
      salaryInfo: null,
      invoiceSelected: null,
      interval: 4,
      paidOrders: 0,
      search: null,
      searchManager: null,
      messages: [],
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      },
      headers: [
        {
          text: 'Number',
          align: 'center',
          value: 'number',
          filterable: false,
          sortable: false,
          width: '100px',
          cellClass: 'pointer'
        },
        {
          text: 'Status',
          value: 'status',
          filterable: true,
          sortable: false,
          width: '150px',
          class: 'px-0',
          cellClass: 'pointer'
        },
        {
          text: 'Project',
          value: 'name',
          filterable: false,
          sortable: false,
          class: 'max-width-10p',
          cellClass: 'pointer'
        },
        {
          text: 'Date of creation',
          value: 'createdAt',
          filterable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Start date',
          value: 'dateStart',
          filterable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Update date',
          value: 'updatedAt',
          filterable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Expiration date',
          value: 'dateEnd',
          filterable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Client',
          value: 'client',
          sortable: false,
          width: '10%',
          cellClass: 'pointer'
        },
        {
          text: 'Company',
          value: 'company',
          sortable: false,
          width: '10%',
          cellClass: 'pointer'
        },
        {
          text: 'Assignee',
          value: 'assignee',
          sortable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Final cost',
          value: 'totalCost',
          filterable: false,
          cellClass: 'pointer'
        },
        {
          text: 'Salary cost',
          value: 'salaryPayments',
          filterable: false,
          cellClass: 'pointer'
        },
        {
          text: 'Manager',
          value: 'manager',
          filterable: false,
          sortable: false,
          width: '10%',
          cellClass: 'pointer'
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          filterable: false,
          width: '50px',
          cellClass: 'pointer'
        }
      ],
      orders: [],
      statusLabels: [],
      options: {
        itemsPerPage: 999
      },
      paymentAccounts: [],
      queryOptions: {
        dateEnd: '',
        dateStart: '',
        dateEndDateType: '',
        orderBy: 'NUMBER_DESC',
        paymentStatus: 'ALL',
        page: 1,
        pageSize: 13,
        searchClient: '',
        searchCompany: '',
        searchManager: '',
        searchName: '',
        searchNumber: '',
        searchExecutor: '',
        orderStatuses: []
      },
      tableOptions: {
        totalPages: null
      },
      overlay: false,
      createPayment: {
        date: '',
        amount: '',
        currency: 'USD',
        account: null
      },
      paymentRemainder: {
        amount: 0,
        currency: 'USD'
      },
      createDesignerPayment: {
        date: null,
        amount: '',
        currency: 'USD',
        account: null,
        extraFee: '',
        extraFeeCurrency: 'USD'
      }
    }
  },
  computed: {
    getYearNow () {
      const date = new Date()
      return date.getFullYear()
    },
    date: {
      get () {
        const object = {
          end: this.queryOptions.dateEnd ? locale.parse(this.queryOptions.dateEnd, 'DD.MM.YYYY') : new Date(),
          start: this.queryOptions.dateStart ? locale.parse(this.queryOptions.dateStart, 'DD.MM.YYYY') : new Date()
        }
        return object
      },
      set (val) {
        this.interval = -1
        this.queryOptions.dateStart = val.start ? locale.format(val.start, 'YYYY-MM-DD') : ''
        this.queryOptions.dateEnd = val.end ? locale.format(val.end, 'YYYY-MM-DD') : ''
      }
    },
    createDesignerPaymentDate: {
      get () {
        if (this.createDesignerPayment.date === null || String(this.createDesignerPayment.date).length === 0) return new Date()
        else return this.createDesignerPayment.date
      },
      set (val) {
        this.createDesignerPayment.date = locale.format(val, 'DD.MM.YYYY')
      }
    }
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    changeStatusAndDate(e) {
      this.queryOptions.orderStatuses = e
      this.getOrders()
    },
    tableHeight () {
      this.queryOptions.pageSize = Math.floor((window.innerHeight - 510) / 48) < 7 ? 7 : Math.floor((window.innerHeight - 510) / 48)
    },
    showPrice (item) {
      this.createPayment.amount = +item.paymentRemainder
      this.priceInfo = item
      this.paymentRemainder = {
        amount: +item.paymentRemainder,
        currency: item.currency
      }
      this.createPayment.currency = item.currency ? item.currency : 'USD'
      this.priceInfoShow = true
    },
    changePaymentFromInvoice(item) {
      this.invoiceSelected = item
      this.createPayment.amount = this.invoiceSelected.amount.amount
      this.createPayment.currency = this.invoiceSelected.amount.currency
    },
    showSalary (item) {
      this.priceInfo = item
      this.salaryInfoShow = true
    },
    showDesignerSalary (user) {
      this.salaryInfo = user
      this.createDesignerPayment.amount = +user.total.amount - user.paid.amount
      this.createDesignerPayment.currency = user.paid.currency
      this.createDesignerPayment.extraFeeCurrency = user.paid.currency
      this.salaryDesignerShow = true
    },
    openPriceHistory () {
      this.priceInvoicesShow = false
      this.priceReportsShow = false
      this.priceHistoryShow = true
    },
    openPriceInvoices () {
      this.priceReportsShow = false
      this.priceHistoryShow = false
      this.priceInvoicesShow = true
    },
    openPriceReports() {
      this.priceHistoryShow = false
      this.priceInvoicesShow = false
      this.priceReportsShow = true
    },
    addContract() {
      this.priceHistoryShow = false
      this.priceInvoicesShow = false
      this.priceReportsShow = false
      this.attachContractShow = true
      this.contractDate = new Date()
      this.contractUrl = ''
      this.contractNumber = ''
      this.contractTitle = ''
      if (this.$refs.contractUrl) this.$refs.contractUrl.resetValidation()
    },
    closeSalaryDesignerShow () {
      this.salaryDesignerShow = false
      this.salaryInfo = null
    },
    closePriceInfo () {
      this.invoiceSelected = null
      const vm = this
      if (vm.priceHistoryShow || vm.priceInvoicesShow || vm.priceReportsShow || vm.salaryDesignerShow) {
        vm.priceHistoryShow = false
        vm.priceInvoicesShow = false
        vm.priceReportsShow = false
        vm.salaryDesignerShow = false
        setTimeout(function () {
          vm.priceInfo = null
          vm.salaryInfo = null
          vm.salaryInfoShow = false
          vm.priceInfoShow = false
          this.createPayment = {
            date: '',
            amount: '',
            currency: 'USD',
            account: null
          }
        }, 200)
      } else {
        vm.priceInfoShow = false
        vm.salaryInfoShow = false
        vm.priceInfo = null
        vm.salaryInfo = null
        vm.createPayment = {
          date: '',
          amount: '',
          currency: 'USD',
          account: null
        }
      }
    },
    closePaymentAdditional() {
      this.priceHistoryShow = false
      this.priceInvoicesShow = false
      this.priceReportsShow = false
    },
    color (value) {
      return statusColor(value)
    },
    sortTable () {
      let title = ''
      if (this.options.sortBy.length) {
        switch (this.options.sortBy[0]) {
          case 'number': title = 'NUMBER'
            break
          case 'status': title = 'STATUS'
            break
          case 'name': title = 'PROJECT'
            break
          case 'createdAt': title = 'CREATED_AT'
            break
          case 'dateStart': title = 'START_DATE'
            break
          case 'updatedAt': title = 'UPDATED_AT'
            break
          case 'dateEnd': title = 'EXPIRATION_DATE'
            break
          case 'totalCost': title = 'FINAL_COST'
            break
          case 'salaryPayments': title = 'SALARY_COST'
            break
        }
        if (this.options.sortDesc[0] === true) title += '_DESC'
        else title += '_ASC'
        this.queryOptions.orderBy = title
      }
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    getWeek () {
      const end = new Date()
      const start = new Date()
      start.setDate(start.getDate() - 7)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getThreeMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    getYear () {
      this.queryOptions.dateStart = this.getYearNow + '-01-01'
      this.queryOptions.dateEnd = this.getYearNow + '-12-31'
      const vm = this
      setTimeout(function () {
        vm.interval = 3
      }, 0)
    },
    getAllTime () {
      this.queryOptions.dateStart = null
      this.queryOptions.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 4
      }, 0)
    },
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    datepickerSalaryClick () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerSalaryShow.click()
      }, 0)
    },
    checkPaymentAccounts () {
      this.$store.getters.getPaymentAccounts ? this.paymentAccounts = this.$store.getters.getPaymentAccounts : this.getPaymentAccounts()
    },
    debouncedInput: _.debounce(function (key) {
      this.queryOptions[key] = this.debouncedInputValue[key]
    }, 1000),
    async getOrders() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getOrders ($input: DesignOrderFilterInput! ) {
          design {
            orders {
              paginatedOrders(input: $input) {
                data {
                  id
                  number
                  name
                  createdAt
                  dateEndPrediction
                  dateEnd
                  dateStart
                  expirationDate
                  currency
                  currencySymbol
                  itemsCancellationReasons
                  updatedAt
                  type
                  paidCurrently
                  contractTitle
                  contractUrl
#                  uploadContractUrl
                  invoices {
                    name
                    amount
                    id
                    downloadUrl
                  }
                  completionActs {
                    downloadUrl
                    name
                    id
                    invoice {
                      id
                    }
                  }
                  status {
                    label
                    value
                    id
                  }
                  manager {
                    businessName
                    avatarUrl
                    businessInitials
                  }
                  totalCost
                  client {
                    name
                    company {
                      id
                      name
                    }
                  }
                  executors {
                      businessName
                  }
                  salaryPayments {
                    byDesigner {
                      paid
                      total
                      operations {
                        date
                        amount
                        amountOrderCurrency
                        id
                      }
                      designer {
                        avatarUrl
                        businessInitials
                        businessName
                        team
                        id
                      }
                    }
                    remainder
                    remainderOffice
                    remainderRemote
                    total
                    expectedTotal
                  }
                  operations {
                    moneyIn
                    amountOrderCurrency
                    date
                    id
                  }
                  paymentRemainder
                  paymentDone
                }
                totalCounts {
                  paid
                  total
                  unpaid
                }
                statusCounts {
                    status {
                        id
                        value
                        label
                    }
                    count
                }
                totalPages
                page
                totalItems
                hasNext
                hasPrev
              }
            }
          }
        }`,
        variables: {
          input: {
            dateEnd: this.queryOptions.dateEnd,
            dateStart: this.queryOptions.dateStart,
            orderBy: this.queryOptions.orderBy,
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            paymentStatus: this.queryOptions.paymentStatus,
            searchClient: this.queryOptions.searchClient,
            searchCompany: this.queryOptions.searchCompany,
            searchId: this.queryOptions.searchNumber,
            searchName: this.queryOptions.searchName,
            searchManager: this.queryOptions.searchManager,
            searchExecutor: this.queryOptions.searchExecutor,
            orderStatuses: this.queryOptions.orderStatuses.map(item => item.value)
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.orders = data.data.design.orders.paginatedOrders.data
        this.tableOptions.totalPages = data.data.design.orders.paginatedOrders.totalPages
        this.queryOptions.page = data.data.design.orders.paginatedOrders.page
        this.totalCounts = data.data.design.orders.paginatedOrders.totalCounts
        this.statusCounts = data.data.design.orders.paginatedOrders.statusCounts.reduce((obj, item) => ({
          ...obj,
          [item.status.value]: item.count
        }), {})
        this.loading = false
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        this.loading = false
        console.log(err)
      })
    },
    checkStatusLabels () {
      this.$store.getters.getStatusLabels ? this.statusLabels = this.$store.getters.getStatusLabels : this.getStatusLabels()
    },
    async getStatusLabels () {
      await this.$apollo.query({
        query: gql`query getStatusLabels {
          design {
            orders {
              orderStatusLabels {
                id
                label
                value
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.statusLabels = data.data.design.orders.orderStatusLabels
        this.$store.commit('updateStatusLabels', data.data.design.orders.orderStatusLabels)
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async getContractNumber () {
      if (this.contractUrl) {
        await this.$apollo.query({
          query: gql`query getContractInfo($orderId: ID!, $url: String!) {
            orders {
              orderContractInfo(orderId: $orderId, url: $url) {
                fromDate
                number
                title
              }
            }
          }`,
          variables: {
            orderId: this.priceInfo.id,
            url: this.contractUrl
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          const item = data.data.orders.orderContractInfo
          this.contractDate = item.fromDate ? new Date(item.fromDate) : new Date()
          this.contractNumber = item.number
          this.contractTitle = item.title
        }).catch((err) => {
          this.showSnackbar(['Error', err])
          console.log(err)
        })
      } else {
        this.contractDate = new Date()
        this.contractNumber = ''
      }
    },
    async attachContract () {
      if (this.$refs.contractUrl.validate()) {
        this.attachContractShow = false
        await this.$apollo.mutate({
          mutation: gql`mutation attachContract($id: ID!, $input: SetOrderContractInput!) {
            orders {
              order(id: $id) {
                setContract (input: $input) {
                  contractUrl
                  contractTitle
                }
              }
            }
          }`,
          variables: {
            id: this.priceInfo.id,
            input: {
              dateFrom: this.formatDate(this.contractDate),
              number: this.contractNumber,
              title: this.contractTitle,
              url: this.contractUrl
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.priceInfo.contractUrl = data.data.orders.order.setContract.contractUrl
          this.priceInfo.contractTitle = data.data.orders.order.setContract.contractTitle
          this.showSnackbar(['Success', 'Contract uploaded with success'])
        }).catch((err) => {
          this.showSnackbar(['Error', err])
          console.log(err)
        })
      } else {
        this.contractDate = new Date()
        this.contractNumber = ''
      }
    },
    async getPaymentAccounts () {
      await this.$apollo.query({
        query: gql`query getPaymentAccounts {
          finance {
            accounts {
                type {
                  name
                  bankName
                  color
                  fontColor
                  id
                  logo
                }
                name
                balance
                description
                expirationDate
                lastFourDigits
                id
              }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.paymentAccounts = data.data.finance.accounts
        this.$store.commit('updatePaymentAccounts', data.data.finance.accounts)
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async updatePayment (id) {
      if (this.$refs.priceInfo.$refs.form.validate()) {
        await this.$apollo.mutate({
          mutation: gql`mutation addIncome ($id: ID!, $input: AddPaymentInput! ) {
            orders {
              order(id: $id) {
                addIncome(input: $input) {
                  id
                }
              }
            }
          }`,
          variables: {
            id: this.priceInfo.id,
            input: {
              date: this.createPayment.date,
              amount: this.createPayment.amount + ' ' + this.createPayment.currency,
              accountId: this.createPayment.account.id,
              invoiceId: this.invoiceSelected ? this.invoiceSelected.id : null
            }
          },
          fetchPolicy: 'no-cache'
        }).then(() => {
          this.getOrders()
          this.invoiceSelected = null
          this.showSnackbar(['Success', 'Payment created'])
          this.closePriceInfo()
        }).catch((err) => {
          this.showSnackbar(['Error', err])
          console.log(err)
        })
      }
    },
    async updateDesignerPayment (id) {
      if (this.$refs.salaryOperation.$refs.salaryform.validate()) {
        await this.$apollo.mutate({
          mutation: gql`mutation addIncome ($id: ID!, $input: AddSalaryInput! ) {
            orders {
              order(id: $id) {
                addSalaryPayment(input: $input) {
                  id
                  order {
                    salaryPayments {
                      remainder
                      remainderOffice
                      remainderRemote
                      expectedTotal
                      byDesigner {
                        paid
                        total
                        operations {
                          date
                          amount
                          amountOrderCurrency
                          id
                        }
                        designer {
                          avatarUrl
                          businessInitials
                          businessName
                          team
                          id
                        }
                      }
                      total
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            id: this.priceInfo.id,
            input: {
              date: this.createDesignerPayment.date,
              amount: this.createDesignerPayment.amount + ' ' + this.createDesignerPayment.currency,
              extraFee: this.createDesignerPayment.extraFee ? this.createDesignerPayment.extraFee + ' ' + this.createDesignerPayment.extraFeeCurrency : null,
              accountId: this.createDesignerPayment.account.id,
              designerId: this.salaryInfo.designer.id
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.priceInfo.salaryPayments = data.data.orders.order.addSalaryPayment.order.salaryPayments
          this.salaryInfo = this.priceInfo.salaryPayments.byDesigner.find(el => el.designer.id === this.salaryInfo.designer.id)
          this.createDesignerPayment.amount = +this.salaryInfo.total.amount - +this.salaryInfo.paid.amount
          this.createDesignerPayment.currency = this.salaryInfo.paid.currency
          this.createDesignerPayment.date = null
          this.createDesignerPayment.account = null
          this.getOrders()
          this.showSnackbar(['Success', 'Payment created'])
        }).catch((err) => {
          this.showSnackbar(['Error', err])
          console.log(err)
        })
      }
    },
    async deleteContract () {
      await this.$apollo.mutate({
        mutation: gql`mutation DeleteContract($id: ID! ) {
          orders {
            order(id: $id) {
              deleteContract {
                id
              }
            }
          }
        }`,
        variables: {
          id: this.priceInfo.id
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Contract was deleted with success'])
        this.priceInfo.contractUrl = null
        this.getOrders()
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async deleteOrder (item) {
      await this.$apollo.mutate({
        mutation: gql`mutation DeleteOrder($id: ID! ) {
          design {
            orders {
              order(id: $id) {
                delete
              }
            }
          }
        }`,
        variables: {
          id: item.id
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Order #' + item.number + ': ' + item.name + ' was deleted with success'])
        const index = this.orders.indexOf(item)
        if (this.tableOptions.totalPages < 2) this.orders.splice(index, 1)
        else this.getOrders()
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async removePayment (id) {
      await this.$apollo.mutate({
        mutation: gql`mutation DeletePayment($id: ID!, $idPayment: ID! ) {
          design {
            orders {
              order(id: $id) {
                removePayment(id: $idPayment) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: this.priceInfo.id,
          idPayment: id
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Payment with id ' + id + ' was deleted with success'])
        this.getOrders()
        this.closePriceInfo()
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async changeOrderStatus (item) {
      await this.$apollo.mutate({
        mutation: gql`mutation OrderSetStatus ($id: ID!, $value: DesignOrderStatusEnum! ) {
          design {
            orders {
              order(id: $id) {
                setStatus(value: $value) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          value: item.status.value
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Order #' + item.number + ': ' + item.name + ' was changed status with success'])
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.showSnackbar('Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created() {
  },
  mounted () {
    this.getAllTime()
    this.checkStatusLabels()
    this.checkPaymentAccounts()
    /*
    For testing
      this.getOrder({ id: '789' })
    */
  },
  watch: {
    queryOptions: {
      handler (newVal, oldVal) {
        this.getOrders()
      },
      deep: true
    },
    options: {
      handler () {
        this.sortTable()
      },
      deep: true
    },
    tab() {
      this.queryOptions.paymentStatus = this.tab === 1 ? 'UNPAID' : this.tab === 2 ? 'ALL' : 'PAID'
    },
    $route: {
      immediate: true,
      handler () {
        if (this.id) {
          this.getOrder({ id: this.id })
        } else if (this.new) {
          setTimeout(() => {
            this.$refs.editOrder.addNewOrder()
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.status-select .v-input__append-inner {
  display: none;
}

.orders-tabs {
  min-height: 100px;
  border-radius: 16px 16px 0 0;
  border-top: 1px solid #EEF2F7;
  border-left: 1px solid #EEF2F7;
  border-right: 1px solid #EEF2F7;
  .v-card {
    border-radius: 0;
    background: #FAFBFD;
    border-bottom: 1px solid #EEF2F7;
    svg {
      stroke: #010820;
    }
    &:before {
      content: none;
    }
  }
  .v-item--active {
    background: white;
    border-bottom: 1px solid white;
    color: #7552CC;
    svg {
      stroke: #7552CC;
    }
  }
}
</style>
